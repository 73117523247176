import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CreditResponse, getSession, LoginResponse, setUserSessionData } from '../services/AuthService';

export interface User {
	identityCode: number;
	agency: string;
	name: string;
	login: string;
	password: string;
	status: string;
	secLevel: string;
	company: string;
	changePassword: string;
	datePwdUpdated: string;
	daysToChangePwd: number;
	email: string;
	countryBorn: string;
	distributorUser: number;
	doddFrankAcceptance: string;
	pinCode: number;
	mobile: string;
	email1: string;
	textPin: string;
	requereEmailValidation: boolean;
	fraudAwarenessAcceptance: string;
	fraudPreventionAcceptance: string;
	checkProcessingTokenDate: string;
	checkProcessingToken: string;
	checkProcessingTokenUsed: boolean;
	learnerId: string;
	checkLearningPath: boolean;
	useDoubleAuth: boolean;
	doubleAuthType: string;
	learningPassesLeft: number;
	inactivationDate: string;
	learningStatusToSent?: boolean;
	language: string;
	fname: string;
	lname: string;
	creationDate: string;
	litmosUpdated: boolean;
	toUpdateInLearningSystem: boolean;
	rights: string;
	learnerIdBackup: string;
	learningSkipsUsed: number;
	incomingChannelReleasePin: number;
	learnerIdTest: string;
	postTransactionValidationPin: string;
	mobilePhoneAuthorized: boolean;
	validateGeolocation: boolean;
	useGoogleAuth: boolean;
	googleAuthConfirm: boolean;
	googleAuthSecretKey: string;
	dualAuthType: string;
	requireDualAuth: string;
	tempPinCode: string;
	prefix: string;
	doubleAuthAttempts: number;
	faLoginTime: string;
	deleted: boolean;
	deletedBySystemUser: number;
	deletedByAgencyUser: number;
	learningLanguage: string;
	learningLanguagePreferred: string;
	lastLoginTime: string;
	assignedToAgency: boolean;
	deletionDate: string;
	appId: number;
	idUsr: string;
	usrType: string;
	secLevelDescription: string;
	statusDescription: string;
}

export interface Token {
	key: string;
}

export interface IAuthContextProps {
	user: User | null;
	handleLogin: (creditData: CreditResponse, loginData: LoginResponse, userData: User) => void;
	handleLogout: () => void;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {

	const [user, setUser] = useState<User | null>(null);

	const handleLogin = (creditData: CreditResponse, loginData: LoginResponse, userData: User) => {
		setUser(userData);
		setUserSessionData(
			{
				data: {
					user: userData,
					anonymous_id: loginData.token,
					agencyData: loginData,
					currencyOrigin: loginData.data[0].currency,
					preReceiptType: loginData.data[0].typeReceiptPrint,
					dataCredit: creditData.data[0]
				}
			}
		);
	};

	const handleLogout = () => {
		setUser(null);
		localStorage.removeItem('user');
	};
	const value = useMemo(
		() => ({
			user,
			handleLogin,
			handleLogout
		}),
		[user],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
