export const en = {
	translations: {
		English: 'English',
		Spanish: 'Spanish',
	},
	login: {},
	messages: {
		no_auth: 'No estás autenticado',
	},
	success: {},
	errors: {},
	buttons: {},
	inputs: {},
};
