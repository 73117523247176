import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents, { LANDING, auth } from '../../routes/contentRoutes';
import { ProtectedRoute } from './ProtectedRoute';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	return (
		<Routes>
			{/* <Route element={<ProtectedRoute />}> */}
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			{/* </Route> */}
			{/* {auth.map((page) => (
				<Route key={page.path} {...page} />
			))} */}
			<Route path='*' element={<PAGE_404 />} />

		</Routes>
	);
};

export default ContentRoutes;
