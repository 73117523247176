import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const FullScreenLoader = () => {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: 1000,
			}}>
			<TailSpin color='#00BFFF' height={80} width={80} />
		</div>
	);
};

export default FullScreenLoader;
